import React from "react";

import { graphql } from "gatsby";

import { gql } from "@apollo/client";

import { LoadingIndicatorContainer } from "../../components/shared/LoadingIndicator";
import { useLaravelLighthouseQuery } from "../../providers/GraphqlLaravelLighthouse";
import NewsPage from "../../templates/news/[slug]";
import Error404Page from "../404";

// Those news which aren't generated statically are fetched from the gql-API at run-time
const DynamicNewsPage = ({ params: { slug }, data: { page } }) => {
    const { loading, data } = useLaravelLighthouseQuery(dynamicQuery, {
        variables: { slug },
    });

    return loading ? (
        <LoadingIndicatorContainer />
    ) : data?.article == null ? (
        <Error404Page />
    ) : (
        <NewsPage
            data={{
                page,
                laravelLighthouse: {
                    article: data.article,
                },
            }}
        />
    );
};
export default DynamicNewsPage;

const dynamicQuery = gql`
    query ($slug: String!) {
        article: newsArticle(isPublished: true, slug: $slug) {
            id
            abstract
            articleBody
            createdAt
            updatedAt
            datePublished
            image
            keywords
            section
            slug
            title
            titleAlignment
            video
        }
    }
`;

export const query = graphql`
    query {
        page: pageItemsYaml(templateKey: { eq: "pageItems/news" }) {
            ...PageItem
        }
    }
`;
